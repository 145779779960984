@import url("https://fonts.googleapis.com/css2?family=Archivo:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Urbanist:wght@300;400;500;600;700;800&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "Archivo";
  }
  body {
    @apply bg-theme-50
  }
}

*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  background: #ffffff00;
  border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
  background-color: #38533D;
  border-radius: 10px;
}
